<template>
  <div class="d-flex flex-column flex pa-5">
    <div class="d-flex flex-wrap align-center justify-space-between">
      <h1>Мої налаштування</h1>
      <v-btn color="primary" @click="toAdminPanel()"
        >Вхід до адмін панелі <v-icon class="ml-2">mdi-login</v-icon></v-btn
      >
    </div>
    <!-- <v-switch v-model="switch1" :label="`Email сповiщення: ${switch1.toString()}`"></v-switch>
    <v-switch v-model="switch2" :label="`SMS сповiщення: ${switch1.toString()}`"></v-switch> -->
    <v-switch v-model="switch1" :label="`Email оповіщення`"></v-switch>
    <v-switch v-model="switch2" :label="`SMS оповіщення`"></v-switch>
    <v-form
      class="reset_card_form"
      ref="registerForm"
      v-model="validResetPass"
      lazy-validation
    >
      <h2 class="mb-2">Змiнити пароль</h2>
      <v-row>
        <v-col cols="12">
          <v-text-field
            dense
            outlined
            v-model="oldPassword"
            :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
            :rules="[rules.required, rules.min]"
            :type="show1 ? 'text' : 'password'"
            name="input-10-1"
            label="Уведiть старий пароль"
            @click:append="show1 = !show1"
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-text-field
            dense
            outlined
            v-model="password"
            :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
            :rules="[rules.required, rules.min]"
            :type="show1 ? 'text' : 'password'"
            name="input-10-1"
            label="Уведiть новий пароль"
            @click:append="show1 = !show1"
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-text-field
            dense
            outlined
            v-model="verify"
            :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
            :rules="[rules.required, passwordMatch]"
            :type="show1 ? 'text' : 'password'"
            name="input-10-1"
            label="Пiдвердiть новый пароль"
            @click:append="show1 = !show1"
          ></v-text-field>
        </v-col>
        <v-col class="d-flex justify-center pa-0 mb-3" cols="12">
          <v-btn :disabled="!valid" color="success" @click="submitResetPass()"
            >Зберегти</v-btn
          >
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
export default {
  name: 'SettingsComponent',
  metaInfo: {
    title: 'Мої налаштування'
  },
  data: () => ({
    switch1: true,
    switch2: true,

    valid: true,
    validResetPass: true,
    oldPassword: '',
    password: '',
    verify: '',

    show1: false,
    rules: {
      required: (value) => !!value || "Обов'язковe поле",
      min: (v) => (v && v.length >= 8) || 'Мiнiмум 8 символiв'
    }
  }),
  computed: {
    passwordMatch () {
      return () => this.password === this.verify || 'Паролi повиннi збiгатися'
    }
  },
  methods: {
    submitResetPass () {
      if (this.validResetPass) {
        // this.signUp({
        //   firstName: this.firstName,
        //   lastName: this.lastName,
        //   email: this.email,
        //   password: this.password
        // });
        // console.log('reset');
      }
    },
    toAdminPanel () {
      const token = this.$cookies.get('_token')
      window.open(
        `${process.env.VUE_APP_ADMIN_URL}auth/token?key=${token}`,
        '_blank'
      )
    }
  }
}
</script>

<style lang="scss" scoped></style>
